* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #ffffff;
}

.animatedPage {
  width: 100%;
}

.landingPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.landingAnimation {
  width: 300px;
}

.backBtn img {
  height: 30px;
  width: 30px;
  position: relative;
  top: 60px;
  left: 15px;
  transition: 0.3s ease;
}

.backBtn img:hover {
  filter: brightness(0);
}

.header {
  z-index: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 25vh;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#swLogo {
  width: 270px;
  margin-right: 20px;
  filter: brightness(0);
  transition: 0.3s ease;
}

#swLogo:hover {
  filter: brightness(1);
}

.homePage {
  margin-top: 25vh;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(3, minmax(470px, 1fr));
  grid-gap: 5px;
}

.jobsToDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.jobsToDisplay h1 {
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  overflow: hidden;
}

.jobsToDisplay img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
}

.navLinks {
  display: flex;
  flex-direction: column;
}

.navLinkButton {
  color: #000000;
  text-decoration: none;
  transition: 0.1s ease;
  margin-top: -4px;
}

.navLinkButton:hover {
  color: #e35ea0;
}

.navLinks {
  margin-left: 20px;
  font-size: 28px;
  color: rgb(227, 94, 160);
  letter-spacing: -2px;
  font-weight: 900;
}

.navLinks img {
  width: 30px;
  margin-right: 10px;
}

.socialLogos img {
  margin-top: 5px;
  filter: brightness(1);
  transition: 0.2s ease;
}

.socialLogos img:hover {
  filter: brightness(0);
}

.about {
  width: 100vw;
  height: 100vh;
  color: #151515;
  font-size: 28px;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about p {
  font-weight: lighter;
  width: 800px;
  letter-spacing: -1px;
}

.jobAboutContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px;
}

.jobAboutContainer video {
  margin-top: 120px;
  width: 650px;
  height: 366px;
}

.jobAboutContainer img {
  margin-top: 120px;
  width: 650px;
  height: 366px;
}

.jobAbout p {
  margin-top: 25px;
  text-align: left;
  width: 650px;
  color: #151515;
}

.jobAbout p {
  font-weight: 100;
}

.jobAboutContainer audio {
  margin-right: 150px;
}

.letsJam {
  width: 100%;
  margin-top: 50px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.letsJam p {
  width: 700px;
  font-weight: 100;
  letter-spacing: -1px;
  text-align: left;
  font-size: 28px;
}

.letsJam img {
  margin-top: 10px;
  width: 35px;
  border-radius: 5px;
  filter: brightness(0);
  transition: 0.3s ease;
}

.letsJam img:hover {
  filter: brightness(1);
}

.letsJam a {
  text-decoration: none;
  color: black;
  transition: 200ms ease;
  margin-bottom: 5px;
}

.letsJam a:hover {
  color: #e35ea0;
}

.nextPage {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease;
  filter: brightness(0);
}

.nextPage img,
.prevPage img {
  width: 20px;
  height: 20px;
}

.nextPage:hover {
  filter: brightness(1);
}

.prevPage {
  position: absolute;
  left: 3%;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease;
  filter: brightness(0);
}

.prevPage:hover {
  filter: brightness(1);
}

@media only screen and (max-width: 600px) {
  .landingPage {
    height: 85vh;
  }

  .backBtn img {
    height: 20px;
    width: 20px;
    top: 10px;
    left: 5px;
  }

  .header {
    height: 15vh;
  }

  #swLogo {
    width: 200px;
    margin: 10px;
  }

  .homePage {
    margin-top: 15vh;
    grid-template-columns: repeat(1, 1fr);
  }

  .jobsToDisplay h1 {
    font-size: 18px;
    width: 100%;
  }

  .jobsToDisplay img {
    width: 100%;
  }

  .navLinks {
    font-size: 18px;
    margin-left: 10px;
    letter-spacing: -1px;
  }

  .about {
    width: 100%;
    height: 85vh;
    font-size: 18px;
  }

  .about p {
    margin-top: 18px;
    width: 100%;
    max-width: 300px;
  }

  .jobAboutContainer {
    padding: 20px;
  }

  .jobAboutContainer video,
  .jobAboutContainer img {
    width: 100%;
    height: auto;
  }

  .letsJam {
    height: 85vh;
  }

  .about br,
  .letsJam br {
    display: none;
  }

  .jobAbout p,
  .letsJam p {
    width: 100%;
    max-width: 300px;
  }

  .letsJam p {
    font-size: 18px;
    width: 300px;
  }

  .letsJam img {
    width: 20px;
    border-radius: 5px;
  }

  #email {
    margin-top: 20px;
    font-size: 16px;
  }

  .prevPage {
    display: none;
  }
  .nextPage {
    display: none;
  }
}
